<template>
  <nav>
    <v-app-bar
        app
        flat
        color="grey lighten-4"
    >
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <div class="d-flex flex-wrap mx-4 align-center py-0 fill-height" v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn
            v-for="(link, idx) in links_filtered"
            :key="idx"
            text
            class="mr-1"
            :to="{ name: link.link }"
            exact
            :exact-active-class="`${accent_color} ${white_text}`"
        >
          {{ link.name }}
        </v-btn>

        <v-spacer></v-spacer>

      </div>
    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        app
        temporary
    >
      <div class="py-8 px-4 grey lighten-4 text-center" style="font-size: 1.2rem">
        {{ organization_data.organization_short_name || '' }}
      </div>
      <v-list
          nav
          dense
      >
        <v-subheader>
          Навігація
        </v-subheader>
        <v-list-item-group
            v-model="group"
            :active-class="accent_text_color"
        >
          <v-list-item
              v-for="(link, idx) in links_filtered"
              :key="`nav-${idx}`"
              :to="{ name: link.link }"
              exact
          >
            <v-list-item-icon>
              <v-icon color="grey darken-1">
                {{ link.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ link.name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "Navigation",
  computed: {
    ...mapGetters({
      organization_data: 'getSiteOrganization',
      site_config: 'getSiteConfig'
    }),
    links_filtered() {
      if (!this.site_config) return []

      const shows = []

      Object.keys(this.site_config).map(item => {
        if (item.indexOf('show_') !== -1) {
          if (this.site_config[item]) {
            shows.push(item)
          }
        }
      })

      return this.links.filter(item => {
        if (!item.condition) return true
        return shows.includes(item.condition);
      })
    },
    accent_text_color() {
      const color = this.site_config.site_color || 'secondary'
      const color_splitted = color.split(' ')

      return `${color_splitted[0]}--text ${color_splitted.length > 1 ? color_splitted[1] + '--text' : ''}`
    },
    accent_color() {
      return this.site_config.site_color || 'secondary'
    },
    white_text() {
      return this.site_config.main_page?.white_button_text ? 'white--text' : ''
    }
  },
  data() {
    return {
      drawer: false,
      group: null,
      links: [
        { name: 'Головна', link: 'SiteHomePage', icon: 'mdi-home-city-outline', condition: '' },
        { name: 'Документи', link: 'SiteDocuments', icon: 'mdi-file-document-outline', condition: 'show_documents' },
        { name: 'Боржники', link: 'SiteDebt', icon: 'mdi-account-cash-outline', condition: 'show_debt' },
        { name: 'Управління', link: 'SiteManagement', icon: 'mdi-account-tie-outline', condition: 'show_management' },
        { name: 'Галерея', link: 'SiteGallery', icon: 'mdi-file-image-outline', condition: 'show_gallery' },
        { name: 'Контакти', link: 'SiteContacts', icon: 'mdi-card-account-phone-outline', condition: 'show_contacts' },
        { name: 'Персональний кабінет', link: 'Login', icon: 'mdi-view-dashboard-outline', condition: 'show_personal_cabinet' },
      ]
    }
  },
}
</script>

<style scoped lang="scss">
  .active-button {
    background-color: #e2e2e2 !important;
  }
</style>